import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import {HomeComponent} from './home/home.component';
import {ServicesComponent} from './services/services.component';
import {PortfolioComponent} from './portfolio/portfolio.component';
import {CareersComponent} from './careers/careers.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {ProductsComponent} from './products/products.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TermsConditionsComponent} from './terms-conditions/terms-conditions.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',             component: HomeComponent },
    { path: 'services',         component: ServicesComponent },
    { path: 'products',         component: ProductsComponent },
    { path: 'portfolio',        component: PortfolioComponent },
    { path: 'careers',          component: CareersComponent },
    { path: 'contact-us',       component: ContactUsComponent },
    { path: 'privacy-policy',   component: PrivacyPolicyComponent },
    { path: 'terms-conditions', component: TermsConditionsComponent },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
