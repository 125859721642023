import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { RecaptchaModule } from 'ng-recaptcha';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeComponent } from './home/home.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ServicesComponent } from './services/services.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CareersComponent } from './careers/careers.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { WhatWeDoComponent } from './what-we-do/what-we-do.component';
import { TeamComponent } from './team/team.component';
import { OurClientsComponent } from './our-clients/our-clients.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { SiteMapComponent } from './site-map/site-map.component';
import { KeepInTouchComponent } from './keep-in-touch/keep-in-touch.component';
import { ProjectInMindComponent } from './project-in-mind/project-in-mind.component';
import { ProductsComponent } from './products/products.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { ServiceCardComponent } from './components/service-card/service-card.component';
import { CustomerCardComponent } from './components/customer-card/customer-card.component';
import { OurProductsComponent } from './our-products/our-products.component';
import { ProductCardComponent } from './components/product-card/product-card.component';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        HomeComponent,
        TermsConditionsComponent,
        PrivacyPolicyComponent,
        ServicesComponent,
        ContactUsComponent,
        CareersComponent,
        PortfolioComponent,
        WhoWeAreComponent,
        WhatWeDoComponent,
        TeamComponent,
        OurClientsComponent,
        TestimonialsComponent,
        SiteMapComponent,
        KeepInTouchComponent,
        ProjectInMindComponent,
        ProductsComponent,
        InfoCardComponent,
        ServiceCardComponent,
        CustomerCardComponent,
        OurProductsComponent,
        ProductCardComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgbModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        RecaptchaModule.forRoot()
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
