import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-keep-in-touch',
  templateUrl: './keep-in-touch.component.html',
  styleUrls: ['./keep-in-touch.component.scss']
})
export class KeepInTouchComponent implements OnInit {

  form: FormGroup;
  canSubmit: boolean;

  constructor(private fb: FormBuilder, private http: HttpClient) { 
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
    });
  }

  onSubmit() {
    console.log('Submit form');

    const {name, email, message} = this.form.value;
    let formRequest = { name, email, message };

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'POST'});
      
    this.http.post('https://us-central1-xiobit-website.cloudfunctions.net/contactUs', formRequest, { headers: headers})
      .subscribe(response => {
        console.log('Contact Form Sent.');

        this.form.reset();
      });
  }
  
  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse != null && captchaResponse != "") {
      this.canSubmit = true;
    } else {
      this.canSubmit = false;
    }
  }

}
